import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, retry } from 'rxjs/operators';
import { Role } from '../models/role';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //public
  public currentUser: Observable<User>;

  //private
  private _currentUserSubject: BehaviorSubject<User>;
  private _profile: any;
  private _retryNo: number = 3;
  private _delayNo: number = 1000;

  constructor(
    private _router: Router,
    private _http: HttpClient,
  ) {
    this._currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this._currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this._currentUserSubject.value;
  }

  get isHO() {
    return this.currentUser && this._currentUserSubject.value.role === Role.HO;
  }

  get isBranch() {
    return this.currentUser && this._currentUserSubject.value.role === Role.Branch;
  }

  get isCustomer() {
    return this.currentUser && this._currentUserSubject.value.role === Role.Customer;
  }

  get userToken() {
    return this.currentUserValue.token;
  }

  get getUsername() {
    return this.currentUserValue.username;
  }

  async getProfile(pToken: string) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${pToken}`
    });

    this._profile = await this._http.get(`${environment.portalBEUrl}/backend/api/profile`, { headers: reqHeader })
      .pipe(
        retry(this._retryNo),
        delay(this._delayNo),
      )
      .toPromise();

    var user = {
      id: this._profile.message.data.id,
      username: this._profile.message.data.username,
      email: this._profile.message.data.email,
      fullName: this._profile.message.data.fullname,
      label: this._profile.message.data.label,
      role: this.setRole(this._profile.message.data.privilege),
      phone: this._profile.message.data.phone,
      token: pToken,
    };
    localStorage.setItem('currentUser', JSON.stringify(user))

    this._currentUserSubject.next(user);
    this.goToHome();
  }

  setRole(privilageId: number) {
    switch (privilageId) {
      case 1:
        return Role.HO

      case 2:
        return Role.HO

      case 3:
        return Role.Branch

      case 4:
        return Role.Customer

      case 5:
        return Role.Customer

      case 6:
        return Role.Customer

      default:
        return Role.Customer
    }
  }

  goToHome() {
    setTimeout(() => {
      if (this.isHO) {
        this._router.navigate(['/head/home']);
      } else if (this.isBranch) {
        this._router.navigate(['/branch/home']);
      } else if (this.isCustomer) {
        this._router.navigate(['/cust/home']);
      }
    });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this._currentUserSubject.next(null);
  }
}
