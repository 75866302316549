import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreCommonModule } from '@core/common.module';

import { LoginComponent } from './login/login.component';
import { ErrorInterceptorProvider } from 'app/main/helpers/error.interceptor';

// routing
const routes: Routes = [
  {
    path: 'login/:token',
    component: LoginComponent,
    data: { animation: 'auth' }
  }
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule
  ],
  providers: [
    ErrorInterceptorProvider,
  ],
})
export class AuthenticationModule { }
